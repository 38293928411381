import { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/decrypt-data/'

export const useDataDecrypt = (dataEncoded) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const decryptData = async () => {
      try {
        const response = await fetch( API_URL,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data_encoded: dataEncoded })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setData(data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    decryptData();
  }, [dataEncoded]);

  return { data, loading, error };
};


// version to use in a non-hook context
export const decryptData = async (dataEncoded) => {
    try {
      const response = await fetch( API_URL,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data_encoded: dataEncoded })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return { data: data.data, loading: false, error: null };
    } catch (err) {
      return { data: null, loading: false, error: err };
    }
  };
  
