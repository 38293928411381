import React, { useEffect } from 'react'
import Participant from './Participant'
import { useRoom } from 'use-twilio-video'
import Button from '@mui/material/Button'
import { MicOff, Mic, VideoCameraBack } from '@mui/icons-material'

function Room({ token, identity, roomName, onDisconnected, errorHandle }) {
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    dominantSpeaker,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone
  } = useRoom()

  useEffect(() => {
    if (!room && token && roomName) {
      connectRoom({ token, options: { name: roomName, dominantSpeaker: true } })
      return () => disconnectRoom()
    }

    let identity = roomName
    if (remoteParticipants[0] !== undefined) {
      identity = remoteParticipants[0].identity
    }

    if ((remoteParticipants.length >= 2 || identity !== roomName) && room) {
      disconnectRoom()
      errorHandle()
    }

  }, [connectRoom, disconnectRoom, dominantSpeaker, onDisconnected, room, roomName, token, remoteParticipants, errorHandle])

  if (error) {
    disconnectRoom()
    errorHandle()
  }

  if (room) {
    let object = Object.fromEntries(room['participants'])
    if (!Object.keys(object).length) {
      setTimeout(() => {
        disconnectRoom()
        errorHandle()
      }, 300)

    }
  }

  // connected
  if (room)
    return (
      <div className='room-container' style={{
        height: '100%',
        backgroundColor: 'black',
      }}>
        <div style={{ 'width': '100%', 'display': 'flex', 'justifyContent': 'space-evenly', alignContent: 'center', 'position': 'absolute', 'zIndex': 1, 'marginTop': '10px', backgroundColor: '#00000055' }}>
          <Button
            onClick={() => {
              disconnectRoom()
              onDisconnected && onDisconnected()
            }} variant="outlined"
            style={{ color: '#ffffff', borderColor: '#ffffff', margin: '5px', borderRadius: '25px' }}>
            Desconectar
          </Button>
          <Button onClick={() => toggleCamera()} variant="outlined" style={{ color: '#ffffff', borderColor: '#ffffff', margin: '5px', borderRadius: '25px' }} >
            {isCameraOn ? <VideoCameraBack style={{ color: 'red' }} /> : <VideoCameraBack style={{ color: 'Gray' }} />} Camara
          </Button>
          <Button variant="outlined" onClick={() => toggleMicrophone()} style={{ color: '#ffffff', borderColor: '#ffffff', margin: '5px', borderRadius: '25px' }}>
            {isMicrophoneOn ? <Mic style={{ color: 'red' }} /> : <MicOff style={{ color: 'Gray' }} />} Microfono
          </Button>
        </div>
        {remoteParticipants.map(p => (
          <div style={{ 'zIndex': 0, 'position': 'absolute', 'height': '100%', 'width': '100%' }}>
            <Participant participant={p} />
          </div>
        ))}
        <div style={{
          'height': '100%',
          'position': 'absolute',
          'width': '100%',
          'display': 'flex',
          'align-items': 'flex-end',
          'justify-content': 'flex-end'
        }} >
          <div style={{ 'zIndex': 0, 'position': 'absolute', 'height': '30%', 'width': '40%' }}>
            <Participant participant={localParticipant} />
          </div>
          <div />
        </div>
      </div>
    )

  return 'connecting...'
}

export default Room
