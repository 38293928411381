import '@fontsource/roboto/500.css';
import '../css/login.css';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import ReactGa from 'react-ga4';


export function Login({ setToken }) {

    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [alert, setAlert] = useState();

    const error = <Alert severity="error">Usuario o Contraseña Incorrectos</Alert>;

    return (
        <React.Fragment>
            {alert}
            <Stack className="form-login" spacing={2}>
                <TextField label="User" onChange={e => setUserName(e.target.value)} />
                <TextField type="password" label="Password" onChange={e => setPassword(e.target.value)} />
                <Button className="login-btn" variant="contained" onClick={() => login()}>Login</Button>
            </Stack>
        </React.Fragment>
    );

    function login() {
        if ((userName === "carlos" && password === "innovacion2023") || (userName === "afp crecer" && password === "Crecer01") || (userName === "seguros crecer" && password === "Crecer02")) {
            setToken(true);
            ReactGa.event({
                category: "Loging",
                action: userName,
                label: userName, // optional
            });

        } else {
            setAlert(error);
        }
    }

}



