import { useEffect, useState } from "react";
import io from "socket.io-client";

let socket

export default function useWebsocket() {
  const [message, setMessage] = useState("");
  const [audio, setAudio] = useState("");
  const [action, setAction] = useState();
  const [isConnected, setIsConnected] = useState(true)

  useEffect(() => {
    if (socket) {
      socket.on("message", receiveMessage)
      socket.on("audio", receiveAudio)
      socket.on("action", receiveAction)
      socket.on("disconnect", receiveDisconnection)
      socket.on("connect", receiveConnection)
      socket.on("error", receiveDisconnection)
    }
    return () => {
      if (socket) {
        socket.off("message", receiveMessage)
        socket.off("audio", receiveAudio)
        socket.off("action", receiveAction)
        socket.on("disconnect", receiveDisconnection)
        socket.on("connect", receiveConnection)
        socket.on("error", receiveDisconnection)
      }
    };
  }, [socket]);

  const disconnect = () => {
    if (socket) {
      socket.disconnect();
    }
  }

  const connect = () => {
    socket = io(process.env.REACT_APP_AI_API_URL, { transports: ['websocket'] });
    socket.connect();
  }

  const receiveMessage = (message) => {
    setMessage(message);
  }


  const receiveAudio = (audio) => {
    setAudio(audio);
  }

  const receiveAction = (action) =>
    setAction(action);

  const receiveDisconnection = (event) => {
    setIsConnected(false)
  }

  const receiveConnection = (event) => {
    setIsConnected(true)
  }


  const handleSubmit = (text, token, sessionId) => {
    const newMessage = {
      prompt: text,
      token: token,
      sessionId: sessionId,
      date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
    };
    socket.emit("message", newMessage);
  };

  const cancelTasks = () => {
    socket.emit("cancel_all_tasks");
  };
  return { action, message, audio, handleSubmit, disconnect, connect, cancelTasks, isConnected };
}